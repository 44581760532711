import React from "react"
import { graphql, navigate} from "gatsby"
import rehypeReact from "rehype-react"
import {Card, Row} from "react-bootstrap"

import AdminLayout from "../components/v2/admin-layout"
import SEO from "../components/seo"
import { PLink } from "../components/partials/base";

import MarkdownQueryFilterDiv from "../components/partials/markdown-query-filter"

import LoginForm from "../components/partials/forms/login-form"
import ExpertiseAdminForm from "../components/partials/forms/expertise-admin-form"
import SectionAdminForm from "../components/partials/forms/section-admin-form"

import UserHelper from "../utils/helpers/user"

import "../utils/css/components/admin.css";

class AdminForm extends React.Component{
    render () {
        const {section, page, title} = this.props;

        if(section === "Expertise"){
            return <ExpertiseAdminForm page={page} title={title} section={section}/>
        }
        else if(section === "Section"){
            return <SectionAdminForm page={page} title={title} section={section}/>
        }
        else{
            return <div></div>
        }
    }
}

class TitleDiv extends React.Component {
    render () {
        const {children} = this.props;
        return <div className="admin-title-div">{children}</div>
    }
}

class TitleDivTitle extends React.Component{
    render () {
        const {children, separator} = this.props;
        return <div className={`admin-title-div--title${separator !== undefined ? " admin-title-div--title-separator" : ""}`}> {children} </div>
    }
}

class TitleDivBreadcrumbs extends React.Component{
    render () {
        const {children} = this.props;
        return <div className="admin-title-div--breadcrumbs">{children}</div>
    }
}

class TitleDivBreadcrumbItem extends React.Component{
    render () {
        const {children, link, separator} = this.props;
        return <div className="admin-title-div--breadcrumbs-item">
            <PLink to={link}>{children}</PLink>
            {separator !== undefined? <span className="admin-title-div--breadcrumbs-separator">&ndash;</span> : <></>}
        </div>
    }
}

class DashboardDiv extends React.Component {
    render () {
        const {children} = this.props;
        return <>
            <Row>
                <div className="admin-card-desk-list col-sm-12">{children}</div>
            </Row>
        </>
    }
}

class CardListDiv extends React.Component {
    render () {
        return   <Card border="light" style={{ width: '18rem' }}>
        <Card.Header>Header</Card.Header>
        <Card.Body>
          <Card.Title>Light Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the bulk
            of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
    }
}

class LoginDiv extends React.Component {
    render () {
        return <div className="row">
            <div className="offset-sm-4 col-sm-4">
                <LoginForm className="login-form" apiUrl={""}/>
            </div>
        </div>
    }
}

class WelcomeUserDiv extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            user: {}
        };
    }

    async componentDidMount(){
        const user = UserHelper.GetUser();
        this.setState({
            loaded: true, user: user
        });
    }
    
    render() {
        const {loaded, user} = this.state;

        return (loaded ? <Row className="welcome-user-div">
            <div className="col-sm-12">
                <Card body>Welcome back, {user.fullname}!</Card>
            </div>
        </Row> : <></>)
    }
}

const CustomMarkdownComponents = {
    "admin-form" : AdminForm,
    "dashboard-div" : DashboardDiv,
    "cardlist-div" : CardListDiv,
    "login-div" : LoginDiv,
    "title-div" : TitleDiv,
    "title-div-title" : TitleDivTitle,
    "title-div-breadcrumbs" : TitleDivBreadcrumbs,
    "title-div-breadcrumb-item" : TitleDivBreadcrumbItem,
    "query-filter-div" : MarkdownQueryFilterDiv,
    "welcome-user-div" : WelcomeUserDiv
};

class AdminPageTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.parChild = React.createRef()
        this.parChildDetails = React.createRef()
        this.state = {
            loaded : true,
            isError: false,
            loggedIn: false,
            checked: false,
            navBar: false
        };
    }

    async componentDidMount (){
        const hasLoggedInUser = UserHelper.HasLoggedInUser();
        this.setState({
            loggedIn : hasLoggedInUser,
            checked: true
        });
    }

    render() {
        const renderAst = new rehypeReact({
            createElement: React.createElement,
            components: CustomMarkdownComponents
        }).Compiler
        
        const post = this.props.data.markdownRemark, {loggedIn, checked} = this.state, {postkey} = post.frontmatter;

        const AdminContent = ({loggedIn}) => {
            if((loggedIn && postkey !== "login") || (!loggedIn && postkey === "login")){
                return <div className="admin-page">
                    <div className="admin-page--content">
                        {renderAst(post.htmlAst)}
                    </div>
                </div>
            }
            else{
                navigate(`/console`);
                return null;
            }
        }

        return (
            <AdminLayout location={this.props.location} navBar={loggedIn}>
                <SEO title={post.frontmatter.title} siteTitle={`ProSource BPO > Admin Console`} />
                {checked ? <AdminContent loggedIn={loggedIn}/> : <></>}
            </AdminLayout>
        )
    }
}

export default AdminPageTemplate

export const pageQuery = graphql`
  query AdminBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        api {url}
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      frontmatter {
        title
        postsection
        postkey
        hasapi
      }
    }
  }
`