import React from "react"
import {navigate} from "gatsby";

import {FormGroupInput, FormGroupSubmit} from "../form"

import SiteLogo from "../../../../content/assets/prosource-logo-color-centre.png"

import APIRequest from "../../../utils/helpers/api"
import UserHelper from "../../../utils/helpers/user"
import { Alert } from "../widgets";

export default class ContactForm extends React.Component {
    state = {
        username: "",
        password: "",
        submitMessage : "Sign in",
        login: false,
        showAlert: false,
        alertComponent: <></>
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({
            showAlert: false,
            alertComponent: <></>,
            "submitMessage" : "Processing..."
        });

        const {username, password} = this.state;

        APIRequest.Post({
            "urlPath" : "/users/login",
            "reqBody" : {
                "username" : username,
                "password" : password
            }
        }, (err, response) =>{
            if(err){
                console.log(err);
                const alert = <Alert message={<><b>Ooops!</b> You have entered invalid credentials!</>} type="danger"/>
                this.setState({
                    showAlert: true,
                    alertComponent: alert,
                    submitMessage: "Sign in"
                });
            }
            else{
                this.setState({
                    showAlert: false,
                    alertComponent: <></>,
                    submitMessage: "Signing in..."
                });
                UserHelper.LoginUser(response.data.data, function (err){
                    if(err){
                        console.log(err);
                    }
                    else{
                        navigate("/console");
                    }
                });
            }
        });
    }

  render() {
    const {className} = this.props, {submitMessage, showAlert, alertComponent} = this.state;
    
    return ( 
        <form onSubmit={this.handleSubmit} className={`prosource-form${className ? " " + className : ""}`}>
            <div className="form-label">
                <img src={SiteLogo} alt="site-logo"></img>
            </div>
            {
                showAlert ? alertComponent : <></>
            }
            <FormGroupInput 
                labelName="Username"
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handleInputChange}
            ></FormGroupInput>
            <FormGroupInput 
                labelName="Password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleInputChange}
            ></FormGroupInput>
            <FormGroupSubmit>{submitMessage}</FormGroupSubmit>
        </form>
    )
  }
}