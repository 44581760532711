/**
 * Login Helpers
*/

import Cookies from "universal-cookie"

const cookies = new Cookies();

function GetUser () {
    return cookies.get("prosource-user");
}

function HasLoggedInUser () {
    const user = GetUser();
    if(user !== null && user !== undefined){
        return true;
    }
    else{
        return false;
    }
}

function LoginUser ({user, session, sessionID}, callback) {
    const {expires} = session.cookie;
    cookies.set('prosource-session', sessionID, { path: '/', expires: new Date(expires)});
    cookies.set('prosource-user', JSON.stringify(user), {path : '/', expires: new Date(expires)})

    return callback();
}

function LogoutUser(callback){
    cookies.remove("prosource-session", {path: '/'});
    cookies.remove("prosource-user", {path: '/'});

    return callback();
}

const UserHelper = {
    GetUser: GetUser,
    HasLoggedInUser: HasLoggedInUser,
    LoginUser: LoginUser,
    LogoutUser: LogoutUser
};

export default UserHelper;