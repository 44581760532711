import React from "react"
import { graphql, StaticQuery } from "gatsby"
import {Card, CardDeck} from "react-bootstrap"

import {PLink} from "../partials/base"

import APIHelper from "../../utils/helpers/api"

class DashboardCard extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            description: ""
        };
    }

    async componentDidMount(){
        const {section, postkey} = this.props;
        let hasApi = false;

        if(["expertise", "section"].includes(section)){
            hasApi = true;
        }

        if(hasApi){
            APIHelper.Get(`/contents/${section}/${postkey}?json=true`, (err, result) => {
                if(err){
                    this.setState({ isError: true })  
                }
                else{
                    let description = "";

                    switch(section){
                        case "expertise" : description = result.data.introduction.hero; break;
                        default: description = "";
                    }
                    this.setState({description: description});
                }
            });
        }   
        else{
            if(section === "dashboard"){
                this.setState({description: `Manage the ${postkey} contents under the ${postkey} dashboard.`});
            }
        }

    }

    render (){
        const {name, link, linkText} = this.props, {description} = this.state;
        return <Card>
            <Card.Header>{name}</Card.Header>
            <Card.Body>
                <Card.Text>{description}</Card.Text>
                <PLink to={link}>{linkText}</PLink>
            </Card.Body>
        </Card>
    }
}

const MarkdownQueryFilter = ({data, section}) => {
    const {edges} = data.allMarkdownRemark;
    return (
        edges.filter(({node}) => { 
            const {postsection} = node.frontmatter;

            return postsection === section;
        }).map(({node}) =>{
            const {title, posttype, postsection, postkey} = node.frontmatter, {slug} = node.fields;
            
            return <CardDeck><DashboardCard name={title} link={`console${slug}`} linkText={`Manage`} section={postsection} type={posttype} postkey={postkey}/></CardDeck>
        })
    )
}

export default props =>{
    return <StaticQuery query={graphql`
        query {
        allMarkdownRemark(sort: {fields: [frontmatter___date], order: ASC}, limit: 1000, filter: {frontmatter: {posttype: {eq: "admin"}}}) {
            edges {
                node {
                fields {
                    slug
                }
                frontmatter {
                    title
                    posttype
                    postsection
                    postkey
                    }
                }
                }
            }
        }`} render={data => (
            <MarkdownQueryFilter location={props.location} data={data} section={props.section} {...props}/>
        )}/>
    
} 

