import React from "react"
import Scroller from "../scrollbar"
import AdminNavbar from "../partials/admin-navbar";

// import MaterialSnackbar from "../partials/material/snackbar"

import "../../utils/css/components/scrolls.css"
import "bootstrap/dist/css/bootstrap.min.css"

class Layout extends React.Component {
    render() {
        const { children, navBar = true} = this.props;

        return (
            <div className="prosource-admin-wrapper">
                <div className="prosource-admin-header-wrapper">
                    {navBar ? <AdminNavbar/> : <></>}
                </div>
                <div className="prosource-admin-main-wrapper">
                    <Scroller>
                        <main className="">
                            <div id="swup">{children}</div>
                        </main>
                    </Scroller>
                </div>
                <div className="prosource-admin-footer-wrapper"></div>
            </div>
        )
    }
}

export default Layout
