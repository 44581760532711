import React from "react"
import {Navbar, Nav, NavDropdown} from "react-bootstrap"

import SiteLogo from "../../../content/assets/prosource-icon-512x512-180x180.png"

import UserHelper from "../../utils/helpers/user"
import { navigate } from "gatsby";

class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded : false,
            user : {}
        };

        this.handleLogout = this.handleLogout.bind(this);
    }    

    handleLogout = event =>{
        UserHelper.LogoutUser((err)=> {
            if(err){
                console.log(err);
            }
            else{
                navigate("/console/login");
            }
        }); 
    }

    async componentDidMount() {
        const user = UserHelper.GetUser();

        this.setState({
            user: user,
            loaded: true
        });
    }

    render () {
        const {loaded, user} = this.state;

        const NavbarComponent = ({user}) => {
            return <Navbar bg="light" expand="lg">
                <Navbar.Brand href={`/console`}>
                <img
                    src={SiteLogo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="site-logo"
                />
                </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="prosource-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        <NavDropdown title="ProSource BPO" className="prosource-nav-bar-dropdown">
                            <NavDropdown.Item href="/">Visit Site</NavDropdown.Item>
                        </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <NavDropdown title={`Hi, ${user.fullname}`} className="prosource-nav-bar-dropdown">
                            <NavDropdown.Item href="#action/3.1">Settings</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={this.handleLogout}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
            </Navbar>
        }

        return (loaded ? <NavbarComponent user={user}/> : <></>)
    }
}

export default AdminNavbar;