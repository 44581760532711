import React from "react"
import {FormColumn, FormRow, FormItemBuilder, FormGroupSubmit} from "../form"
import MaterialSnackbar from "../material/snackbar"

import APIHelper from "../../../utils/helpers/api"
class ExpertiseAdminForm extends React.Component{
    constructor(props) {
        super(props);
        const content = {}; //GlobalState.PageContent;

        this.state = {
            "form" : content,
            "status" : "ready",
            "submitMessage" : "",
            "loaded" : false,
            "hasError" : false,
            "errorMessage" : "",
            "showSnackbar" : false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.nameSplitter = this.nameSplitter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isNumber = this.isNumber.bind(this);
    }

    nameSplitter = (name) =>{
        const x = name.indexOf("_");
        return [name.substr(0, x), name.substr(x+1, name.length)];
    }

    isNumber = (x) =>{
        return !isNaN(parseInt(x))
    }

    handleInputChange = event => {
        const target = event.target
        let value = target.value
        const name = target.name
        const [key, childkey] = this.nameSplitter(name);

        // check if childkey is a part of iteration variable
        const [childkeyname, childkeyindex] = this.nameSplitter(childkey), isPartOfIteration = this.isNumber(childkeyindex);

        let newValue = this.state.form[key], newForm = this.state.form;

        if(isPartOfIteration){
            if(childkeyname === "list"){
                value = value.split(",");
            }
            newValue[childkeyindex][childkeyname] = value;
            newForm[key][childkey] = newValue;
            this.setState({
                form: newForm
            });
        }
        else{
            newValue[childkey] = value; 
            newForm[key] = newValue;

            this.setState({
                form: newForm
            });
        }
    }

    async handleSubmit (event) {
        const {page} = this.props, url = `/contents/expertise/${page}`;

        event.preventDefault();
        this.setState({
            "status" : "submitting",
            "submitMessage" : "",
            "showSnackbar" : false
        });

        APIHelper.Put({
            "urlPath" : url,
            "reqBody" : {
                "value" : JSON.stringify(this.state.form)
            }
        }, (err, response) =>{
            if(err){
                this.setState({
                    hasError: true, error: err
                });
            }
            else{
                this.setState({
                    "status" : "ready",
                    "submitMessage" : "Changes saved!",
                    "showSnackbar" : true
                });

                setTimeout(() =>{
                    this.setState({"submitMessage" : ""})
                }, 2000);
            }
        });
    }

    async componentDidMount () {
        const {page} = this.props;
        APIHelper.Get(`/contents/expertise/${page}?json=true`, (err, result) => {
            if(err){
                this.setState({ isError: true })  
            }
            else{
                this.setState({form: result.data, loaded: true});
            }
        });
    }

    render (){
        const {status, submitMessage} = this.state,
        FormFields = [
            {
                "section" : "Introduction",
                "fields" : [
                    {
                        "type" : "row",
                        "subfields" : [
                            {
                                "name" : "sidetitle",
                                "key" : "introduction",
                                "type" : "text",
                                "label" : "Side Title",
                                "width" : 3
                            },
                            {
                                "name" : "hero",
                                "key" : "introduction",
                                "type" : "text",
                                "label" : "Hero Title",
                                "width" : 9
                            }
                        ]
                    },
                    {
                        "type" : "row",
                        "subfields" : [
                            {
                                "name" : "subhero_a",
                                "key" : "introduction",
                                "type" : "textarea",
                                "label" : "Sub Hero A",
                                "width" : 6
                            },
                            {
                                "name" : "subhero_b",
                                "key" : "introduction",
                                "type" : "textarea",
                                "label" : "Sub Hero B",
                                "width" : 6
                            }
                        ]
                    }
                ]
            }, 
            {
                "section" : "Introduction Stats",
                "fields" : [
                    {
                        "name" : "introstats",
                        "type" : "iteration",
                        "subfields" : [
                            {
                                "name" : "number",
                                "key" : "introstats",
                                "type" : "text", 
                                "label" : "Number",
                                "width" : 6
                            },
                            {
                                "name" : "label",
                                "key" : "introstats",
                                "type" : "text", 
                                "label" : "Label",
                                "width" : 6
                            }
                        ]
                    } 
                ]
            },
            {
                "section" : "Description",
                "fields" : [
                    {
                        "type" : "row",
                        "subfields" : [
                            {
                                "name" : "sidetitle",
                                "key" : "description",
                                "type" : "text",
                                "label" : "Side Title",
                                "width" : 3
                            },
                            {
                                "name" : "hero",
                                "key" : "description",
                                "type" : "text",
                                "label" : "Hero Title",
                                "width" : 9
                            }
                        ]
                    },
                    {
                        "type" : "row",
                        "subfields" : [
                            {
                                "name" : "subhero_a",
                                "key" : "description",
                                "type" : "textarea",
                                "label" : "Sub Hero A",
                                "width" : 6
                            },
                            {
                                "name" : "subhero_b",
                                "key" : "description",
                                "type" : "textarea",
                                "label" : "Sub Hero B",
                                "width" : 6
                            }
                        ]
                    }
                ]
            },
            {
                "section" : "Glance",
                "fields" : [
                    {
                        "name" : "glance",
                        "type" : "iteration",
                        "subfields" : [
                            {
                                "name" : "title",
                                "key" : "glance",
                                "type" : "text", 
                                "label" : "Title",
                                "width" : 3
                            },
                            {
                                "name" : "list",
                                "key" : "glance",
                                "type" : "text", 
                                "label" : "List",
                                "width" : 9
                            },
                        ]
                    }
                ]
            }, 
            {
                "section" : "Stats",
                "fields" : [
                    {
                        "name" : "stats",
                        "type" : "iteration",
                        "subfields" : [
                            {
                                "name" : "number",
                                "key" : "stats",
                                "type" : "text", 
                                "label" : "Number",
                                "width" : 2
                            },
                            {
                                "name" : "emphasizedDesc",
                                "key" : "stats",
                                "type" : "text", 
                                "label" : "Highlighted Text",
                                "width" : 5
                            },
                            {
                                "name" : "normalDescription",
                                "key" : "stats",
                                "type" : "text", 
                                "label" : "Normal Text",
                                "width" : 5
                            },
                        ]
                    }
                ]
            },
            {
                "section" : "Roles",
                "fields" : [
                    {
                        "name" : "roles",
                        "type" : "iteration",
                        "subfields" : [
                            {
                                "name" : "highlighted",
                                "key" : "roles",
                                "type" : "text", 
                                "label" : "Highlighted Text",
                                "width" : 6
                            },
                            {
                                "name" : "normal",
                                "key" : "roles",
                                "type" : "text", 
                                "label" : "Normal Text",
                                "width" : 6
                            }
                        ]
                    }
                ]
            }
        ]; 

        let submitButtonLabel = `Submit Changes`;

        if(status === "submitting"){
            submitButtonLabel = "Submitting...";
        }

        const FormValues = this.state.form, {loaded, showSnackbar} = this.state, {title} = this.props;

        return (!loaded ? <></> : 
        <div className="admin-page-form">
            <div className="admin-page-form-header">
                <div className="admin-page-form-header--title">
                    {title}
                </div>
            </div>
            <div className="admin-page-form-body">
                <form onSubmit={this.handleSubmit} className={`prosource-form prosource-form--admin-expertise`}>
                    { 
                            FormFields.map(({section, fields}) =>{
                                return <div className="admin-page-form--section">
                                    <div className="admin-page-form--section-name">{section}</div>
                                    <div className="admin-page-form--section-fields">
                                        {
                                            fields.map(({name, key, type, label, subfields}) =>{
                                                if(type === "iteration"){// For iteration
                                                    return  <FormRow> {
                                                        FormValues[name].map((formValueItem, index) =>{
                                                            return subfields.map((subfieldItem) =>{
                                                                return <FormColumn width={subfieldItem.width}>
                                                                    <FormItemBuilder label={subfieldItem.label} type={subfieldItem.type} name={`${subfieldItem.key}_${subfieldItem.name}_${index}`} value={formValueItem[subfieldItem.name]} onChange={this.handleInputChange}/>
                                                                    </FormColumn>
                                                                })
                                                            })
                                                        }
                                                    </FormRow>
                                                }
                                                else if(type === "row"){
                                                    if(subfields){
                                                        return <FormRow>
                                                        {
                                                            subfields.map(({key, label, name, type, width}) => {
                                                                return <FormColumn width={width}>
                                                                    <FormItemBuilder label={label} type={type} name={`${key}_${name}`} value={FormValues[key][name]} onChange={this.handleInputChange}/>
                                                                </FormColumn>
                                                            })
                                                        }
                                                        </FormRow>
                                                    }
                                                    else{
                                                        return <></>;
                                                    }
                                                }
                                                else{
                                                    return <FormRow singleColumn={true}>
                                                        <FormItemBuilder label={label} type={type} name={`${key}_${name}`} value={FormValues[key][name]} onChange={this.handleInputChange}/>
                                                    </FormRow>
                                                }
                                            })
                                        } 
                                    </div>
                                </div>
                            })
                        }
                        <FormGroupSubmit submitMessage={submitMessage}>{submitButtonLabel}</FormGroupSubmit>
                </form>
                {showSnackbar ? <MaterialSnackbar open={true} variant={`success`} message={`Changes saved!`}/> : <></>}
            </div>
        </div>)
    }
}

export default ExpertiseAdminForm;