import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    danger: ErrorIcon,
    info: InfoIcon
};

class MaterialSnackbar extends React.Component {
    constructor(props){
        super(props);
        const {open = false} = props;
        this.state = {
            open: open
        }

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = event =>{
        this.setState({
            open: false
        });
    }
    
    render() {
        const {open} = this.state, {snackbarId = "snackbar-id", message = "Sample notification", variant="success"} = this.props, Icon = variantIcon[variant];

        return <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={this.handleClose}
            ContentProps={{
            'aria-describedby': snackbarId,
            className: `prosource-material-snackbar--root prosource-material-snackbar--root-${variant}`
            }}
            className={`prosource-material-snackbar`}
            message={<span id={snackbarId} className={`prosource-material-snackbar--message`}>
                 <Icon className={`prosource-material-snackbar--icon`}/>
                {message}
            </span>}
        />
    }
}

export default MaterialSnackbar;